import axios from '@axios'

export default {
  namespaced: true,
  state: {
    Price :{},
    calendarOptions: [
      {
        color: 'danger',
        label: 'Personal',
      },
      {
        color: 'primary',
        label: 'Business',
      },
      {
        color: 'warning',
        label: 'Family',
      },
      {
        color: 'success',
        label: 'Holiday',
      },
      {
        color: 'info',
        label: 'ETC',
      },
    ],
    selectedCalendars:{} ,
   
  },
  getters: {},
  mutations: {
    
    GET_PRICE(state,payload) {
      console.log("payload",payload)
      state.Price = payload
     
    },
    SET_SELECTED_EVENTS(state, val) {
      console.log("val",val)
      state.selectedCalendars = val
    },
   
  },
  actions: {
  

    // ------------------------------------------------
    // Course Actions
    // ------------------------------------------------
    addCourseOnline(ctx, payload) {
     
        return new Promise((resolve, reject) => {
          axios
            .post('schedules',  payload ,{ headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      addDate(ctx, payload) {
        return new Promise((resolve, reject) => {
          axios
            .post('schedules',  payload ,{ headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      Deleteschedule(ctx, id) {
        console.log("DDcdb")
        console.log("payload",id)
        return new Promise((resolve, reject) => {
          axios
            .delete(`schedules/${id}` )
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      DeleteSelected(ctx, payload) {
        console.log("schedules")
        console.log("payload",payload)
        return new Promise((resolve, reject) => {
          axios
            .post('schedules-bulk-delete',payload )
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      AllCategory(ctx,) {
        console.log("queryParams")
        return new Promise((resolve, reject) => {
          axios
            .get('https://londonma-en.prokoders-staging-4.xyz/api/categories?filter[search]=&perPage=50&page=1')
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      saveChanges(ctx,payload ) {
    
  
        return new Promise((resolve, reject) => {
          axios
            .post(`save-events-changes`,payload,{ headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      fetchEvents(ctx, queryParams) {
        let city_id=[]
        let courseid=queryParams.course_id.id
        console.log("queryParams")
        console.log("queryParams",queryParams)
        queryParams.city_id.forEach(el => {
         city_id.push(el.id)
          
        });
        
  
        return new Promise((resolve, reject) => {

          axios
            .get(`scheduled-courses?course_id=${courseid}&city_id=${JSON.stringify(city_id)}&category_id=${queryParams.category_id}&online=0 `)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },GetScheduleCourse(ctx,{id} ) {
        console.log(id)
        
  
        return new Promise((resolve, reject) => {
          axios
            .get(`schedule/${id}`)
            .then(response => {
              console.log(response?.data.data)
             
              resolve(response)
            }
              )
            .catch(error => reject(error))
        })
      },
      Updateschedule(ctx, payload) {
        console.log("DDcdb")
        console.log("payload",payload)
        return new Promise((resolve, reject) => {
          axios
            .post(`schedules/${payload.id}`,  payload.formData  ,{ headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      }
      ,getAllInstructor(ctx) {
    
        
  
        return new Promise((resolve, reject) => {
          axios
            .get(`instructors`)
            .then(response => {
              console.log(response?.data.data)
             
              resolve(response)
            }
              )
            .catch(error => reject(error))
        })
      },
  getAllschedules(ctx,queryParams ) {
  
    return new Promise((resolve, reject) => {
      axios
        .get('scheduled-courses-all',{ params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  getCourseSchedule(ctx,queryParams ) {
  
    return new Promise((resolve, reject) => {
      axios
        .get('schedules-list',{ params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
},

}
