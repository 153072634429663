import Vue from 'vue'
import Vuex from 'vuex'

// Modules

import app from './app'
import lpcstore from './lpc-store'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import courseStoreModule from '@/views/courses/courseStoreModule'
import categoriesStoreModule from '@/views/categories/categoriesStoreModule'
import othercourseStoreModule from '@/views/othercourses/othercourseStoreModule'
import instructorStoreModule from '@/views/Instructor/instructorStoreModule'
import priceStoreModule from '@/views/prices/priceStoreModule'
import citiesStoreModule from '@/views/citiesandlocation/citiesStoreModule'
import contentStoreModule from '@/views/content/contentStoreModule'
import schduleStoreModule from '@/views/Schedule/scheduleStoreModule'
import homepageStoreModule from '@/views/homepage/homepageStoreModule'
import seoStoreModule from '@/views/seo/seoStoreModule'
import accreditationsStoreModule from '@/views/accreditations/accreditationsStoreModule'
import ClientStoreModule from '@/views/clients/ClientStoreModule'
import slidesStoreModule from '@/views/slider/slidesStoreModule'
import galleryStoreModule from '@/views/gallery/galleryStoreModule'
import settingStoreModule from '@/views/setting/settingStoreModule'
import blogStoreModule from '@/views/blog/blogStoreModule'
import contactUsStoreModule from '@/views/contact-us/contactUsStoreModule'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    courses: courseStoreModule,
    categories: categoriesStoreModule,
    othercourses: othercourseStoreModule,
    instructor: instructorStoreModule,
    cities: citiesStoreModule,
    price: priceStoreModule,
    content: contentStoreModule,
    schedule: schduleStoreModule,
    homepage: homepageStoreModule,
    seo: seoStoreModule,
    accreditations: accreditationsStoreModule,
    clients: ClientStoreModule,
    slides: slidesStoreModule,
    gallery: galleryStoreModule,
    setting: settingStoreModule,
    blog: blogStoreModule,
    contactUs: contactUsStoreModule,

  },
  strict: process.env.DEV,
})
