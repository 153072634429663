export default [
  {
    path: '',
    name: 'index-certificate',
    component: () => import('@/views/certificates/index.vue'),
   
    children: [
    {
      path: '/certificates/add-certificates',
      name: 'add-certificates',
      component: () => import('@/views/certificates/AddCertificates.vue'),
      meta:{
        resource: 'certificate',
        action:'view'
      },
    },
    {
      path: '/certificates/update-certificates/:id',
      name: 'update-certificates',
      component: () => import('@/views/certificates/UpdateCertificate.vue'),
      meta:{
        resource: 'certificate',
        action:'view'
      },
    },
   
    {
      path: '/certificates/all-certificates',
      name: 'all-certificates',
      component: () => import('@/views/certificates/ViewCertificates.vue'),
      meta:{
        resource: 'certificate',
        action:'view'
      },
    },
  ]},
    
    
    
    {
      path: '/gallery/',
      name: 'gallery-index',
      component: () => import('@/views/gallery/index.vue'),
      children:[
    
    
    {
      path: '/add-gallery-images',
      name: 'add-gallery-images',
      component: () => import('@/views/gallery/addGallery.vue'),
      meta:{
        resource: 'image',
        action:'view'
      }
    },
    {
      path: '/gallery-images',
      name: 'gallery-images',
      component: () => import('@/views/gallery/GalleryList.vue'),
      meta:{
        resource: 'image',
        action:'view'
      }
    },
    {
      path: '/gallery-video',
      name: 'gallery-video',
      component: () => import('@/views/gallery/addVideo.vue'),
      meta:{
        resource: 'image',
        action:'view'
      }
    },
    {
      path: '/update-image/:id',
      name: 'update-image',
      component: () => import('@/views/gallery/updateimage.vue'),
      meta:{
        resource: 'image',
        action:'view'
      }
    },
  ]},
    
    
  ]
  