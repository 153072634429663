import axios from '@axios'

export default {
  namespaced: true,
  state: {
    city :{}
   
  },
  getters: {},
  mutations: {
    
    GET_CITY(state,payload) {
    
      state.city = payload
     
    },
   
  },
  actions: {
  

    // ------------------------------------------------
    // Course Actions
    // ------------------------------------------------
    addcity(ctx, payload) {
       
      
        return new Promise((resolve, reject) => {
          axios
            .post('cities',  payload ,{ headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      addlocation(ctx, payload) {
       
      
        return new Promise((resolve, reject) => {
          axios
            .post('locations',  payload ,{ headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      UpdateCity(ctx, payload) {
       
      
        return new Promise((resolve, reject) => {
          axios
            .post(`cities/${payload.id}`,  payload.formData ,{ headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      Updatelocation(ctx, payload) {
       
      
        return new Promise((resolve, reject) => {
          axios
            .post(`locations/${payload.id}`,  payload.formData ,{ headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      DeleteCity(ctx, id) {
       
       
        return new Promise((resolve, reject) => {
          axios
            .delete(`cities/${id}` )
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      DeleteSelected(ctx, payload) {
       
      
        return new Promise((resolve, reject) => {
          axios
            .post('cities-bulk-delete',payload )
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      DeleteLocation(ctx, id) {
       
       
        return new Promise((resolve, reject) => {
          axios
            .delete(`locations/${id}` )
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      GetCity(ctx,{id} ) {
     
        
  
        return new Promise((resolve, reject) => {
          axios
            .get(`cities/${id}`)
            .then(response => {
          
             
              resolve(response)
            }
              )
            .catch(error => reject(error))
        })
      },
      GetLocation(ctx,{id} ) {
     
        
  
        return new Promise((resolve, reject) => {
          axios
            .get(`locations/${id}`)
            .then(response => {
          
             
              resolve(response)
            }
              )
            .catch(error => reject(error))
        })
      },
      getAllcitites(ctx,queryParams ) {
  
        return new Promise((resolve, reject) => {
          axios
            .get('cities',{ params: queryParams })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      cititesList(ctx ) {
  
        return new Promise((resolve, reject) => {
          axios
            .get('city-list')
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      getAllLocation(ctx, queryParams) {
  
        return new Promise((resolve, reject) => {
          axios
            .get('locations',{ params: queryParams })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },

  getAllcitysClassical(ctx, ) {
  
    return new Promise((resolve, reject) => {
      axios
        .get('citys?filter[online] = 0')
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
},

}
