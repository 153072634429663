import axios from '@axios'

export default {
  namespaced: true,
  state: {
    city :{}
   
  },
  getters: {},
  mutations: {
    
    GET_CITY(state,payload) {
      console.log("payload",payload)
      state.city = payload
     
    },
   
  },
  actions: {
  

    // ------------------------------------------------
    // Course Actions
    // ------------------------------------------------
    addCourseContent(ctx, payload) {
        console.log("DDcdb")
        console.log("payload",payload)
        return new Promise((resolve, reject) => {
          axios
            .post('course-content',  payload ,{ headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      addUpComingCourse(ctx, payload) {
        console.log("DDcdb")
        console.log("payload",payload)
        return new Promise((resolve, reject) => {
          axios
            .post('upcoming-courses',  payload ,{ headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      DeleteUPComing(ctx, id) {
      
        return new Promise((resolve, reject) => {
          axios
            .delete(`upcoming-courses/${id}` )
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      DeleteFeatureCourse(ctx, id) {
      
        return new Promise((resolve, reject) => {
          axios
            .delete(`feature-course/${id}` )
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      addAllUpComingCourse(ctx, payload) {
    
        return new Promise((resolve, reject) => {
          axios
            .post('upcoming-courses-visible-toggle',  payload )
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      AllUpComingCourse(ctx, queryParams) {
      
        return new Promise((resolve, reject) => {
          axios
            .get('upcoming-courses', { params: queryParams })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      DeleteSelected(ctx, payload) {
        console.log("DDcdb")
        console.log("payload",payload)
        return new Promise((resolve, reject) => {
          axios
            .post('upcoming-courses-bulk-delete',payload )
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      AllFeatureCourse(ctx, queryParams) {
      
        return new Promise((resolve, reject) => {
          axios
            .get('feature-course', { params: queryParams })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      updateFeature(ctx, payload) {
        console.log("DDcdb")
        console.log("payload",payload)
        return new Promise((resolve, reject) => {
          axios
            .post(`feature-course/${payload.id}`,  payload.formData ,{ headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      CreateFeature(ctx, payload) {
        console.log("DDcdb")
        console.log("payload",payload)
        return new Promise((resolve, reject) => {
          axios
            .post(`feature-course`,  payload ,{ headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      getscheduleByCourse(ctx, payload) {
        console.log("payload,payload")
      
        return new Promise((resolve, reject) => {
          axios
            .get(`schedules-list?filter[course_id]=${payload.courseid}&filter[city_id]=${payload.cityid}` )
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      GetFeatureCourse(ctx) {
 
  
        return new Promise((resolve, reject) => {
          axios
            .get(`feature-course`)
            .then(response => {
              console.log(response?.data.data)
             
              resolve(response)
            }
              )
            .catch(error => reject(error))
        })
      },
      getScheduleDate(ctx, ) {
  
        return new Promise((resolve, reject) => {
          axios
            .get('other-schedules')
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      getOtherCourse(ctx, ) {
  
        return new Promise((resolve, reject) => {
          axios
            .get('other-scheduled-courses')
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      getAllCourses(ctx, ) {
  
        return new Promise((resolve, reject) => {
          axios
            .get('other-scheduled-courses')
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },

      getAllUComingCourse(ctx, ) {
  
    return new Promise((resolve, reject) => {
      axios
        .get('upcoming-courses')
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
},

}
