export default [  {
  path: '/content',
      name: 'index-content',
      component: () => import('@/views/content/index.vue'),
  children: [

    {
      path: '/category-content',
      name: 'category-content',
      component: () => import('@/views/content/CategoryContent.vue'),
      meta:{
         resource: 'course_content',
        action:'view'
      }
    },
    
   
    {
      path: '/category-in-city-content',
      name: 'category-in-city-content',
      component: () => import('@/views/content/CategoryinCityContent.vue'),
      meta:{
         resource: 'course_content',
        action:'view'
      }
    },
    {
      path: '/dynamic-content',
      name: 'dynamic-content',
      component: () => import('@/views/content/HouseTraning.vue'),
      meta:{
         resource: 'course_content',
        action:'view'
      }
    },
    {
      path: '/category-page-content',
      name: 'category-page-content',
      component: () => import('@/views/content/CategoryPageContent.vue'),
      meta:{
         resource: 'course_content',
        action:'view'
      }
    },
    {
      path: '/city-content',
      name: 'city-content',
      component: () => import('@/views/content/CityContent.vue'),
      meta:{
        resource: 'course_content',
        action:'view'
      }
    },
    {
        path: '/course-content',
        name: 'course-content',
        component: () => import('@/views/content/CourseContent.vue'),
        meta:{
          resource: 'course_content',
          action:'view'
        }
      }
  ]
}]