import axios from '@axios'

export default {
  namespaced: true,
  state: {
    accreditation:{}
   
  },
  getters: {},
  mutations: {
   
    GET_ACCREDITATION(state,payload) {
    
      state.accreditation = payload
   
    },
   
  },
  actions: {
  

    // ------------------------------------------------
    // Course Actions
    // ------------------------------------------------
    addAccreditations(ctx, payload) {
    
        return new Promise((resolve, reject) => {
          axios
            .post('accreditations', payload ,{ headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
      },
    
 
      AllAccreditations(ctx, queryParams) {
  
        return new Promise((resolve, reject) => {
          axios
            .get('accreditations', { params: queryParams })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      UpdateAccreditations(ctx,payload) {
      
        return new Promise((resolve, reject) => {
          axios
            .post(`accreditations/${payload.id}`,  payload.formData ,{ headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      DeleteAccreditations(ctx, id) {
      
        return new Promise((resolve, reject) => {
          axios
            .delete(`accreditations/${id}` )
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      GetAccreditations(ctx,{id} ) {
   
        
  
        return new Promise((resolve, reject) => {
          axios
            .get(`accreditations/${id}`)
            .then(response => {
          
             
              resolve(response)
            }
              )
            .catch(error => reject(error))
        })
      },

 
  getAllOnlineCourse(ctx, ) {
  
    return new Promise((resolve, reject) => {
      axios
        .get('courses?filter[online] = 1')
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
},

}
