export default [
  {
    path: "/categories",
    // redirect: "/courses",
    name: 'index-category',
    component: () => import('@/views/categories/index.vue'),
     meta:{
        resource: 'category',
        action:'view'
      },
    children: [
    {
      path: '/add-category',
      name: 'add-category',
      component: () => import('@/views/categories/AddCategory.vue'),
      meta:{
        resource: 'category',
        action:'view'
      },
    },
    {
      path: '/update-category/:id',
      name: 'update-category',
      component: () => import('@/views/categories/UpdateCategory.vue'),
      meta:{
        resource: 'category',
        action:'update'
      },
    },
  
    {
      path: '/all-categories',
      name: 'all-categories',
      component: () => import('@/views/categories/categoriesList.vue'),
      meta:{
        resource: 'category',
        action:'view'
      },
    },
    
  ]
  },
  {
    path: "/contact-us",
    // redirect: "/courses",
    name: 'contact-us',
    component: () => import('@/views/contact-us/index.vue'),
     meta:{
        resource: 'contact-us',
        action:'view'
      },
    children: [
    {
      path: '/add-contact-us',
      name: 'add-contact-us',
      component: () => import('@/views/contact-us/AddContactUs.vue'),
      meta:{
        resource: 'contact-us',
        action:'view'
      },
    },
    {
      path: '/update-contact-us/:id',
      name: 'update-contact-us',
      component: () => import('@/views/contact-us/UpdateContactUs.vue'),
      meta:{
        resource: 'contact-us',
        action:'update'
      },
    },
  
    {
      path: '/all-contact-us',
      name: 'all-contact-us',
      component: () => import('@/views/contact-us/List.vue'),
      meta:{
        resource: 'contact-us',
        action:'view'
      },
    },
    
  ]
  }]
  