export default [
  {
    path: '/articales',
    name: 'index-article',
    component: () => import('@/views/blog/index.vue'),
    // meta:{
    //   resource: 'all',
    //   action:'view'
    // }
    children: [
    {

      path: '/add-article',
      name: 'add-article',
      component: () => import('@/views/blog/AddArticale.vue'),
      meta:{
        resource: 'post',
        action:'view'
      },
    },
    {

      path: '/update-article/:id',
      name: 'update-article',
      component: () => import('@/views/blog/UpdateArticle.vue'),
      meta:{
        resource: 'post',
        action:'view'
      },
    },
  
    
    {
      path: '/all-articales',
      name: 'all-articales',
      component: () => import('@/views/blog/AllArticales.vue'),
      meta:{
        resource: 'post',
        action:'view'
      }
    },
    
  ]
}]